<template>
  <div v-if="isAanwezigheid && !isVariableWerkrooster" class="text-center py-8">
    <h1 class="text-xl sm:text-2xl font-semibold text-gray-900">Je werkrooster is reeds ingesteld op volledige beschikbaarheid.</h1>
    <p class="text-gray-600 mt-2">Je kan hierdoor geen (extra) beschikbaarheden doorgeven.</p>
  </div>
  <div v-else>
    <h1 class="text-xl sm:text-2xl font-semibold text-gray-900">Jouw {{ tabel }} &mdash; {{ MAANDEN[filters.month - 1].label }} {{ filters.year }}</h1>
    <small v-if="MIN_DAYS_BEFORE > 0">{{ capitalize(tabel) }} dienen <strong>{{ MIN_DAYS_BEFORE }} {{ `dag${MIN_DAYS_BEFORE === 1 ? '' : 'en'}` }} op voorhand</strong> aangevraagd te worden.</small>

    <div class="flex gap-4 mt-4 mb-8">
      <button class="mt-6 ml-2" @click="addMonth(-1)"><i class="fas fa-arrow-left"></i></button>
      <UISelectFixed type="MAAND" v-model="filters.month" />
      <UISelectMinMax :min="dateNow.getFullYear()" :max="dateNow.getFullYear() + 1" label="Jaar" v-model="filters.year" />
      <button class="mt-6 mr-2" @click="addMonth(1)"><i class="fas fa-arrow-right"></i></button>
    </div>

    <ul class="flex flex-col gap-2 my-8">
      <li><span class="inline-block w-4 h-4 mr-1 align-middle" :class="itemContextClasses({ verstuurd: true })"></span> Verstuurd</li>
      <li><span class="inline-block w-4 h-4 mr-1 align-middle" :class="itemContextClasses({ goedgekeurd: true })"></span> Goedgekeurd</li>
      <li><span class="inline-block w-4 h-4 mr-1 align-middle" :class="itemContextClasses({ afgekeurd: true })"></span> Afgekeurd</li>
    </ul>

    <ul class="flex flex-col gap-2 text-sm">
      <li v-for="(day, index) in selectedDaysCount" :key="day" class="flex flex-row items-center border border-gray-300 px-2 py-1" :class="{ 'bg-gray-100': day % 2 }">
        <div class="flex flex-row gap-2">
          <button :disabled="dayIsDisabled(day)" @click="() => handleToevoegen(day)" class="btn info rounded"><i class="fa fas fa-plus text-sm"></i></button>
          <strong class="w-24">{{ WEEKDAGEN[(selectedStartDayIndex + index) % 7]?.label }}</strong>
          <span class="w-32">{{ dateStringLocale(`${filters.year}-${filters.month}-${day}`) }}</span>
        </div>
        <span class="w-full flex flex-col gap-1 items-start">
          <ul class="flex-1 w-full flex flex-col gap-1">
            <li v-if="!items[day]?.length" class="italic text-xs text-gray-600 py-1" v-html="labelLeeg(day)"></li>
            <li v-for="(item, index) in items[day]" :key="`${index}-${item.id}`" class="card font-bold flex justify-between items-center" :class="itemClasses(item)">
              <div class="flex flex-row gap-x-2 items-center justify-between w-full">
                <span>{{ item.table_day_start !== day ? '00:00' : timeString(item.time_start) }} - {{ item.table_day_einde !== day ? '23:59' : timeString(item.time_einde) }}</span>
                <span class="bg-purple-200 rounded p-1">Aanvraag #{{ item.id }}</span>
              </div>
              <ButtonAlertOpmerking v-if="item.opmerking" :opmerking="item.opmerking" />
            </li>
          </ul>
        </span>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { onMounted, inject, computed, ref } from 'vue'
import { useStore } from 'vuex'

import { MAANDEN, WEEKDAGEN } from '@/constants/OPTIONS'
import { dateStringLocale, timeString } from '@/functions/formatDate'
import { capitalize } from '@/functions/formatText'
import useGetApi from '@/hooks/useGetApi'
import useModal from '@/hooks/useModal'

import UISelectFixed from '@/components/UI/Select/Fixed.vue'
import UISelectMinMax from '@/components/UI/Select/MinMax.vue'
import ButtonAlertOpmerking from '@/components/UI/Button/AlertOpmerking.vue'

const props = defineProps({
  minDaysBefore: Number,
  tabel: String,
  identifierForm: String,
})

const axios = inject('axios')
const store = useStore()
const [openFixedModal] = useModal({ fixed: true })

const isAanwezigheid = props.tabel === 'aanwezigheden'
// const isAfwezigheid = props.tabel === 'afwezigheden'

const MIN_DAYS_BEFORE = computed(() => Math.max(0, Number(props.minDaysBefore)))
const dateNow = new Date()
const timeNow = new Date(new Date().setHours(0, 0, 0, 0)).getTime()
const minDate = timeNow + (86400000 * MIN_DAYS_BEFORE.value)

const filters = ref({
  month: dateNow.getMonth() + 1,
  year: dateNow.getFullYear(),
  tabel: props.tabel,
})

const addMonth = (amount) => {
  const newMonth = Number(filters.value.month) + amount
  if (newMonth <= 0) {
    if (filters.value.year === dateNow.getFullYear()) return
    filters.value.month = 12
    filters.value.year -= 1
  } else if (newMonth > 12) {
    if (filters.value.year === dateNow.getFullYear() + 1) return
    filters.value.month = 1
    filters.value.year += 1
  } else {
    filters.value.month = newMonth
  }
}

const { data, getData } = useGetApi('CHAUFFEUR_BESCHIKBAARHEID_LIST', filters, { watch: true })

const werkdagen = ref({})
onMounted(async () => {
  if (isAanwezigheid) {
    const { data } = await axios.get('/api/dashboard/werkdagen/list')
    werkdagen.value = data.werkdagen || {}
  }
})

const dagnamen = ['ZO', 'MA', 'DI', 'WO', 'DO', 'VR', 'ZA']
const isVariableWerkrooster = computed(() => !!dagnamen.find(dag => !werkdagen.value[`${dag}_VM`] || !werkdagen.value[`${dag}_NM`]))

const labelLeeg = (day) => {
  const date = new Date(filters.value.year, filters.value.month - 1, day)
  const result = [`Geen aanvraag op ${dateStringLocale(date)}`]
  const dagnaam = dagnamen[date.getDay()]

  if (werkdagen.value[`${dagnaam}_VM`] && werkdagen.value[`${dagnaam}_NM`]) {
    result.push('Je ingesteld werkrooster geeft aan dat je de <b>hele dag</b> al beschikbaar bent.')
  } else if (werkdagen.value[`${dagnaam}_VM`]) {
    result.push('Je ingesteld werkrooster geeft aan dat je de <b>voormiddag</b> beschikbaar bent.')
  } else if (werkdagen.value[`${dagnaam}_NM`]) {
    result.push('Je ingesteld werkrooster geeft aan dat je de <b>namiddag</b> beschikbaar bent.')
  }

  return result.join('<br>')
}

const selectedStartDayIndex = computed(() => {
  const day = new Date(filters.value.year, filters.value.month - 1, 1).getDay()
  if (day === 0) return 6
  return day - 1
})
const selectedDaysCount = computed(() => new Date(filters.value.year, filters.value.month, 0).getDate())
const dayIsDisabled = computed(() => (day) => {
  const date = new Date(filters.value.year, filters.value.month - 1, day)
  if (date.getTime() <= timeNow) {
    return true
  }

  if (isAanwezigheid) {
    const day = new Date(date).getDay()
    const dagnaam = dagnamen[day]
    if (werkdagen.value[`${dagnaam}_VM`] && werkdagen.value[`${dagnaam}_NM`]) {
      return true
    }
  }

  const limit = timeNow + (86400000 * MIN_DAYS_BEFORE.value)
  return date.getTime() < limit
})

const items = computed(() => {
  const result = {}
  for (var day = 1; day <= selectedDaysCount.value; day += 1) {
    result[day] = data?.value?.list?.filter(el => day >= el.table_day_start && day <= el.table_day_einde)
  }
  return result
})

const itemContextClasses = (itemContext) => {
  if (itemContext?.afgekeurd) return 'bg-red-100 border border-red-500 text-gray-900'
  if (itemContext?.goedgekeurd) return 'bg-green-100 border border-green-500 text-gray-900'
  if (itemContext?.verstuurd) return 'bg-yellow-100 border border-yellow-500 text-gray-900'
  return 'bg-blue-100 blue-500 text-gray-900'
}

const itemContext = computed(() => (item) => {
  const verstuurd = item?.status === 'PENDING'
  const goedgekeurd =  item?.status === 'ACCEPTED'
  const afgekeurd = item?.status === 'DENIED'
  return { verstuurd, goedgekeurd, afgekeurd }
})

const itemClasses = computed(() => (item) => {
  if (dayIsDisabled.value(item)) return ''
  return itemContextClasses(itemContext.value(item))
})

const currentMinMonth = new Date(minDate).getMonth() + 1
if (filters.value.month < currentMinMonth) {
  addMonth(currentMinMonth - filters.value.month)
}

const handleToevoegen = (day) => {
  if (dayIsDisabled.value(day)) return
  const modelValue = {
    user_id: store.getters.currentUserId,
    reason: 'AANGEVRAAGD',
    start: new Date(filters.value.year, filters.value.month - 1, day, 0, 0, 0, 0),
    einde: new Date(filters.value.year, filters.value.month - 1, day, 23, 59, 59, 999),
  }

  const callback = () => getData()
  openFixedModal(props.identifierForm, { modelValue, minDate, callback })
}
</script>
